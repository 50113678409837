import { AxiosError } from 'axios'

import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { api } from 'utils/api'

import {
  GetFileResponse,
  UploadFileError,
  UploadFileResponse,
} from 'types/file'

export const useAPIUploadFile = () => {
  return useMutation<UploadFileResponse, AxiosError<UploadFileError>, FormData>(
    (body) =>
      api(`/file/upload`, {
        method: 'POST',
        data: body,
      })
  )
}

export const useGetFileDetail = ({
  fileName,
  opts,
}: {
  fileName: string
  opts?: UseQueryOptions<GetFileResponse, AxiosError<UploadFileError>>
}) => {
  return useQuery<GetFileResponse, AxiosError<UploadFileError>>(
    ['file', fileName],
    async ({ queryKey }) => await api(`/file?name=${queryKey[1]}`),
    { ...opts }
  )
}
