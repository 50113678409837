import Link from 'next/link'
import { BookmarkSimple, User } from 'phosphor-react'
import React from 'react'

import { Avatar } from '@wartek-id/avatar'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleIcon,
  CollapsibleTrigger,
} from '@wartek-id/collapsible'

import { useAPIGetDosenProfile } from 'apis/dosens/useAPIDosens'
import { useGetFileDetail } from 'apis/files/useAPIFiles'

const NavbarProfileCollapsible = () => {
  const { data: dosenProfileData } = useAPIGetDosenProfile()
  const { data: profilePic } = useGetFileDetail({
    fileName: dosenProfileData?.data?.profile_photo_file_name,
    opts: { enabled: !!dosenProfileData },
  })

  return (
    <div>
      <Collapsible>
        <CollapsibleTrigger className="!border-0 !p-0">
          <div className="flex flex-row items-center space-x-4">
            <Avatar
              src={profilePic?.data?.url ?? ''}
              className="!h-10 !w-10"
              alt="profile-picture"
              fallbackColor="blue"
              name={dosenProfileData?.data?.name}
            />
            <div>
              <p className="text-left text-base font-bold">
                {dosenProfileData?.data?.name}
              </p>
              <p className="text-left text-sm font-normal text-[#979B9F]">
                {dosenProfileData.data.position}
              </p>
            </div>
          </div>
          <CollapsibleIcon />
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col space-y-4 pt-4 pl-14">
            <Link href="/account">
              <div className="flex flex-row items-center space-x-2">
                <User size={14} />
                <p className="font-sans text-base font-bold text-gray-90">
                  Akun
                </p>
              </div>
            </Link>

            <Link href="/bookmarked">
              <div className="flex flex-row items-center space-x-2">
                <BookmarkSimple size={14} />
                <p className="font-sans text-base font-bold text-gray-90">
                  Daftar Tersimpan
                </p>
              </div>
            </Link>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}

export default NavbarProfileCollapsible
