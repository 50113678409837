export const isProd = process.env.NODE_ENV === 'production'

export const showLogger = !isProd
  ? true
  : process.env.NEXT_PUBLIC_SHOW_LOGGER === 'true' ?? false

/**
 * A logger function that will only logs on development
 * @param object - The object to log
 * @param eventName - The log event name
 * @param comment - Autogenerated with `lg` snippet
 */
export default function logger(
  object: unknown,
  eventName: string,
  comment?: string
): void {
  if (!showLogger) return

  // eslint-disable-next-line no-console
  console.log(
    `%c============== INFO LOG ============\n%cEvent Details: %c${eventName}\n`,
    'background: #222; color: #bada55; font-weight: bold; padding: 4px 0;',
    'color: #16a34a; font-weight: bold;',
    'color: #fff; font-weight: bold;',
    `URL: ${typeof window !== 'undefined' && window?.location.pathname}\n`,
    `Comment: ${comment ?? '-'}\n`,
    object
  )
}
