import Image from 'next/image'
import Link from 'next/link'
import {
  FacebookLogo,
  Globe,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'phosphor-react'
import React from 'react'

const navigation = {
  pt: [
    {
      name: 'Pusat Informasi',
      href: 'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us',
    },
  ],
  external_links: [
    {
      name: 'Kampus Merdeka untuk Mahasiswa',
      href: 'https://kampusmerdeka.kemdikbud.go.id/',
    },
    {
      name: 'Kampus Merdeka untuk Mitra',
      href: 'https://mitra.kampusmerdeka.kemdikbud.go.id/',
    },
  ],
}

const kemdikbudInfo = [
  { text: 'Jl. Jenderal Sudirman, Senayan, Jakarta 10270', href: '#' },
  {
    text: 'marketing@kampusmerdeka.co',
    href: 'mailto:marketing@kampusmerdeka.co',
  },
]

const SocialMediaLink = (props: {
  name: 'facebook' | 'youtube' | 'instagram' | 'twitter' | 'web'
}) => {
  let link: string
  let icon: React.ReactNode

  switch (props.name) {
    case 'facebook':
      link = 'https://www.facebook.com/mbkm.ri/'
      icon = <FacebookLogo size={24} weight="fill" color="#ffffff" />
      break
    case 'instagram':
      link = 'https://www.instagram.com/kampusmerdeka.ri/'
      icon = <InstagramLogo size={24} weight="fill" color="#ffffff" />
      break
    case 'twitter':
      link = 'https://twitter.com/ditjendikti'
      icon = <TwitterLogo size={24} weight="fill" color="#ffffff" />
      break
    case 'youtube':
      link = 'https://www.youtube.com/channel/UCYXoGiVYq7ExZV1_LZ_p4fA'
      icon = <YoutubeLogo size={24} weight="fill" color="#ffffff" />
      break
    case 'web':
      link = 'https://dikti.kemdikbud.go.id/'
      icon = <Globe size={24} weight="fill" color="#ffffff" />
  }

  return (
    <Link href={link} passHref>
      <a target="_blank" rel="noopener noreferrer">
        <span>{icon}</span>
      </a>
    </Link>
  )
}

export const Footer = () => {
  return (
    <footer
      className="block w-full flex-shrink-0 bg-gray-100"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-fit px-8 py-14 md:px-20 md:py-20">
        <div className="flex flex-col">
          <div className="sm:flex sm:flex-col lg:grid lg:grid-cols-2 lg:gap-36">
            <div className="flex flex-col gap-6 lg:flex-row">
              <div className="flex-shrink-0">
                <Image
                  src="/logo.png"
                  width={120}
                  height={120}
                  alt="Kemdikbud Logo"
                />
              </div>
              <div>
                <h3 className="max-w-md text-lg font-bold text-white">
                  Direktorat Jenderal Pendidikan Tinggi, Riset, dan Teknologi
                  (Diktiristek)
                </h3>
                <div>
                  <ul role="list" className="mt-8 space-y-4">
                    {kemdikbudInfo.map((item) => (
                      <li key={item.text}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300"
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mt-12 lg:mt-0">
                <h3 className="font-serif text-xl leading-6 text-white">
                  Perguruan Tinggi
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.pt.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 lg:mt-0">
                <h3 className="font-serif text-xl leading-6 text-white">
                  Tautan Eksternal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.external_links.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col gap-8 md:mt-40 lg:flex-row">
            <div className=" flex flex-1 flex-row gap-x-6 lg:ml-[144px]">
              <SocialMediaLink name="twitter" />
              <SocialMediaLink name="instagram" />
              <SocialMediaLink name="youtube" />
              <SocialMediaLink name="facebook" />
              <SocialMediaLink name="web" />
            </div>
            <div className="flex-1">
              <p className=" text-sm text-gray-300">
                Hak Cipta 2022 Kementerian Pendidikan dan Kebudayaan RI
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
