import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useState } from 'react'

import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import { GATrackEvent } from 'utils/googleAnalytics'

import { navigation, rightNavigation, userNavigation } from 'components/Navbar'
import { ProfileDropdown } from 'components/ProfileDropdown'

import { useUserStore } from 'stores'

import styles from './style.module.css'

export const SimpleNavbar = (props: {
  title?: string
  previousURL?: string
  mobileHidden?: boolean
}) => {
  const router = useRouter()
  const { logged } = useUserStore()

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const mainNavigation = isLoggedIn ? userNavigation : navigation

  React.useEffect(() => {
    setIsLoggedIn(logged())
  }, [logged])

  const onClickBack = React.useCallback(() => {
    if (props.previousURL) {
      router.push(props.previousURL)
    } else {
      router.back()
    }
  }, [props.previousURL, router])

  const onClickLink = (text, type) => () => {
    GATrackEvent({
      eventName: 'click',
      eventParams: { type, text },
    })
  }

  return (
    <nav className="bg-white sm:bg-gray-100">
      <div
        className={clsx(
          'mx-auto max-w-7xl border-b  border-gray-200 px-2 py-4 sm:px-6 sm:py-5 md:border-none',
          styles.NavbarShadow,
          props.mobileHidden && 'hidden md:block'
        )}
      >
        <div className="relative flex h-5 items-center justify-between sm:h-auto">
          <div className="absolute inset-y-0 left-0 ml-3 flex items-center sm:hidden">
            <Icon className="block h-6 w-6" size={24} onClick={onClickBack}>
              arrow_back
            </Icon>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden flex-shrink-0 items-center sm:flex">
              <Image
                className="block h-8 w-auto sm:block"
                width={75}
                height={40}
                src="/images/logo.png"
                alt="Wartek"
              />
            </div>
            <Text className="sm:hidden" variant="subheading">
              {props.title}
            </Text>
            <div className="hidden flex-row items-center sm:ml-6 sm:flex">
              <div className="flex space-x-4">
                {mainNavigation.map((item) => (
                  <a
                    type="div"
                    onClick={onClickLink(item.name, 'button')}
                    key={item.name}
                    href={item.href}
                    className={clsx(
                      item.current
                        ? 'bg-gray-900 no-underline'
                        : ' no-underline hover:bg-gray-700',
                      ' rounded-md px-3 py-2 text-base font-bold text-white no-underline hover:bg-gray-700'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="hidden lg:block">
              <div className="flex space-x-4">
                {rightNavigation.map((item) => (
                  <a
                    type="div"
                    key={item.name}
                    href={item.href}
                    className={clsx(
                      item.current
                        ? 'bg-gray-900 no-underline'
                        : ' no-underline hover:bg-gray-700',
                      'rounded-md px-3 py-2 text-base font-bold text-white'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>

            <ProfileDropdown />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default SimpleNavbar
