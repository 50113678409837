import * as React from 'react'

import { Footer } from 'components/Footer'
import { Navbar } from 'components/Navbar'

export const Homepage = ({
  children,
  mobileTitle,
}: {
  children: React.ReactNode
  mobileTitle?: string
}) => {
  return (
    <div className="min-h-screen">
      <Navbar title={mobileTitle} />
      {children}
      <Footer />
    </div>
  )
}
