import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'

const APP_LOGIN_STORAGE_KEY = '@kmm/user'
const baseURL = process.env.NEXT_PUBLIC_API_URL

export function getSession(storageKey: string) {
  const storage = window.localStorage.getItem(storageKey)
  const JSONStorage = JSON.parse(storage || '{}')
  return JSONStorage.state.session
}

const paramsSerializer = (params?: Record<string, any>): string =>
  stringify(params ?? {}, { arrayFormat: 'none' })

export async function api<T>(
  path: string,
  options: AxiosRequestConfig = {},
  storageKey: string = APP_LOGIN_STORAGE_KEY,
  tokenFromQueryParam: string | string[] | undefined = undefined
): Promise<T> {
  await refreshSession(storageKey)

  const session = getSession(storageKey)

  if (session?.token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${session.token}`,
    }
  }

  if (tokenFromQueryParam) {
    options.params = {
      ...options.params,
      token: tokenFromQueryParam,
    }
  }

  const requestApi = (): AxiosPromise<T> => {
    const url = `${baseURL}${path}`

    return axios({
      ...options,
      paramsSerializer,
      url,
    })
  }

  const response = await requestApi()
  return response.data
}

export async function refreshSession(storageKey: string) {
  const storage = window.localStorage.getItem(storageKey)
  const JSONStorage = JSON.parse(storage || '{}')
  const session = JSONStorage.state.session

  const currentTime = new Date().getTime()
  const isTokenExpired = session.expiredAt < currentTime

  try {
    if (isTokenExpired) {
      const { data: response } = await axios.post(
        `${baseURL}/user/auth/refresh_token`,
        {
          refresh_token: session.refreshToken,
        }
      )

      JSONStorage.state.session = {
        ...JSONStorage.state.session,
        token: (response as any).data.access_token,
        expiredAt: (response as any).data.expire_at.slice(0, 10) * 1000,
        refreshToken: (response as any).data.refresh_token,
      }

      window.localStorage.setItem(storageKey, JSON.stringify(JSONStorage))
    }
  } catch (error) {
    // TODO: There is chance refresh token is expired, create handler go to login page and ad query for error message
    console.error(error)
    window.location.href = `${window.location.origin}/logout?session=expired`
  }
}
