import Link from 'next/link'
import React from 'react'

import { Button } from '@wartek-id/button'
import { Separator } from '@wartek-id/separator'
import {
  Sheet,
  SheetBody,
  SheetCloseButton,
  SheetContent,
  SheetOverlay,
} from '@wartek-id/sheet'

import { GATrackEvent } from 'utils/googleAnalytics'

import { useUserStore } from 'stores'

import NavbarProfileCollapsible from './NavbarProfileCollapsible'

interface NavbarMobileProps {
  headerMenuShown: boolean
  setHeaderMenuShown: React.Dispatch<React.SetStateAction<boolean>>
}
const NavbarMobile = (props: NavbarMobileProps) => {
  const { logged, logout, user } = useUserStore()

  const { headerMenuShown, setHeaderMenuShown } = props
  const handleClose = () => {
    setHeaderMenuShown(false)
  }

  const handleClickLogout = () => {
    logout()
  }

  const onClickLink =
    (
      link: 'knowledge_center' | 'call_us' | 'home' | 'register' | 'login' | ''
    ) =>
    () => {
      switch (link) {
        case 'knowledge_center':
          return GATrackEvent({
            eventName: 'knowledge_center',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
              location: 'navbar',
            },
          })
        case 'call_us':
          return GATrackEvent({
            eventName: 'knowledge_center',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
              location: 'navbar',
            },
          })
        case 'home':
          return GATrackEvent({
            eventName: 'home',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
              location: 'navbar',
            },
          })
        case 'register':
          return GATrackEvent({
            eventName: 'register',
            eventParams: { location: 'navbar' },
          })
        case 'login':
          return GATrackEvent({
            eventName: 'login',
            eventParams: {},
          })
        default:
          return ''
      }
    }

  return (
    <Sheet
      autoFocus
      closeOnOverlayClick
      onClose={handleClose}
      position="top"
      returnFocus
      sheetSize="auto"
      type="modal"
      isOpen={headerMenuShown}
    >
      <SheetOverlay />
      <SheetContent>
        <SheetBody className="mt-11 p-4">
          <div className="mb-4 flex flex-1 flex-col gap-5">
            {logged() && (
              <>
                <Separator orientation="horizontal" />
                <NavbarProfileCollapsible />
                <Separator orientation="horizontal" />
                <Link href={'/dashboard'} passHref>
                  <a onClick={onClickLink('home')}>
                    <p>Beranda</p>
                  </a>
                </Link>
              </>
            )}

            <Separator orientation="horizontal" />
            <Link
              href={
                'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us'
              }
              passHref
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickLink('knowledge_center')}
              >
                <p>Pusat Informasi</p>
              </a>
            </Link>
            <Separator orientation="horizontal" />

            {logged() ? (
              <div onClick={handleClickLogout}>
                <p>Keluar</p>
              </div>
            ) : (
              <Button color="white">
                <Link
                  href={`${process.env.NEXT_PUBLIC_MBKM_URL}/login`}
                  passHref
                >
                  <a rel="noopener noreferrer" onClick={onClickLink('login')}>
                    Masuk ke Akun
                  </a>
                </Link>
              </Button>
            )}
          </div>
        </SheetBody>
        <SheetCloseButton onClick={handleClose} />
      </SheetContent>
    </Sheet>
  )
}

export default NavbarMobile
