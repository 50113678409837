import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { List, MagnifyingGlass } from 'phosphor-react'
import * as React from 'react'
import { useEffect, useState } from 'react'

import { Text } from '@wartek-id/text'

import { GATrackEvent } from 'utils/googleAnalytics'

import { ProfileDropdown } from 'components/ProfileDropdown'

import { useUserStore } from 'stores'

import NavbarMobile from './NavbarMobile'

import styles from './style.module.css'

export const navigation = []

export const userNavigation = [
  { name: 'Beranda', href: '/dashboard', id: 'home' },
  { name: 'Cari Kerja Sama', href: '/search', id: 'search' },
]

export const rightNavigation = [
  {
    name: 'Pusat Informasi',
    href: 'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us',
    current: false,
    id: 'knowledge_center',
  },
]

export const Navbar = (props: { title?: string; previousURL?: string }) => {
  const router = useRouter()
  const { user, logged } = useUserStore()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [headerMenuShown, setHeaderMenuShown] = useState(false)

  const mainNavigation = isLoggedIn ? userNavigation : navigation

  useEffect(() => {
    setIsLoggedIn(logged())
  }, [logged])

  const onClickLink =
    (
      link:
        | 'knowledge_center'
        | 'call_us'
        | 'home'
        | 'register'
        | 'search'
        | 'call_us_2'
    ) =>
    () => {
      switch (link) {
        case 'knowledge_center':
          return GATrackEvent({
            eventName: 'knowledge_center',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
              location: 'navbar',
            },
          })
        case 'call_us':
          return GATrackEvent({
            eventName: 'knowledge_center',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
              location: 'navbar',
            },
          })
        case 'call_us_2':
          return GATrackEvent({
            eventName: 'knowledge_center',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
            },
          })
        case 'home':
          return GATrackEvent({
            eventName: 'home',
            eventParams: {
              is_login: logged(),
              user_id: user?.id ?? '',
              location: 'navbar',
            },
          })
        case 'register':
          return GATrackEvent({
            eventName: 'register',
            eventParams: { location: 'navbar' },
          })
        case 'search':
          return GATrackEvent({
            eventName: 'search',
            eventParams: {
              location: 'navbar',
              is_login: logged(),
              user_id: user?.id ?? '',
            },
          })
        default:
          return ''
      }
    }

  return (
    <nav className="bg-gray-100">
      <div className={clsx('mx-auto max-w-7xl px-6 py-5', styles.NavbarShadow)}>
        <div className="relative flex h-auto items-center justify-between">
          <div className="flex flex-1 items-center lg:hidden">
            <div className="flex flex-1 items-center justify-start gap-3">
              <Image
                src="/logo.png"
                width={42}
                height={40}
                alt="Kemdikbud Logo"
              />
              <Link href={'/'}>
                <a>
                  <Image
                    className="block h-8 w-auto sm:block"
                    width={75}
                    height={40}
                    src="/images/logo.png"
                    alt="kampus-merdeka"
                  />
                </a>
              </Link>
            </div>
            <div className="flex flex-1 flex-row justify-end gap-4">
              {isLoggedIn && (
                <button
                  onClick={() => {
                    router.push('/search')
                  }}
                >
                  <MagnifyingGlass size={24} className="text-white" />
                </button>
              )}
              <button
                onClick={() => {
                  setHeaderMenuShown(true)
                }}
              >
                <List size={24} color="#ffffff" />
              </button>
            </div>
            <NavbarMobile
              headerMenuShown={headerMenuShown}
              setHeaderMenuShown={setHeaderMenuShown}
            />
          </div>
          <div className="hidden lg:flex lg:flex-1">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="hidden flex-shrink-0 items-center sm:flex">
                <Link href={'/'}>
                  <a>
                    <Image
                      className="block h-8 w-auto cursor-pointer sm:block"
                      width={75}
                      height={40}
                      src="/images/logo.png"
                      alt="kampus-merdeka"
                    />
                  </a>
                </Link>
              </div>
              <Text className="sm:hidden" variant="subheading">
                {props.title}
              </Text>
              <div className="hidden flex-row items-center sm:ml-6 sm:flex">
                <div className="flex space-x-4">
                  {mainNavigation.map((item) => {
                    const isCurrent = router.pathname === item.href
                    return (
                      <a
                        onClick={onClickLink(item.id as 'home' | 'search')}
                        type="div"
                        key={item.name}
                        href={item.href}
                        className={clsx(
                          isCurrent
                            ? 'bg-gray-800'
                            : 'no-underline hover:bg-gray-600',
                          ' rounded-md px-3 py-2 text-base font-bold text-white no-underline hover:bg-gray-700'
                        )}
                        aria-current={isCurrent ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="hidden lg:block">
                <div className="flex space-x-4">
                  {rightNavigation.map((item) => (
                    <a
                      type="div"
                      onClick={onClickLink(
                        item.id as 'knowledge_center' | 'call_us_2'
                      )}
                      key={item.name}
                      href={item.href}
                      className={clsx(
                        item.current
                          ? 'bg-gray-900 no-underline'
                          : ' no-underline hover:bg-gray-700',
                        'rounded-md px-3 py-2 text-base font-bold text-white'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>

              <ProfileDropdown />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
