import { AxiosError } from 'axios'

import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { api } from 'utils/api'

import {
  CreateDosenProfileResponse,
  DosenProfileError,
  Response,
  UpdateDosenProfileBody,
  UpdateDosenProfileResponse,
} from 'types/dosens'
import { DosenProfileResponse } from 'types/dosens'

export const useAPIGetDosenProfile = (
  opts?: UseQueryOptions<DosenProfileResponse, DosenProfileError>
) => {
  return useQuery<DosenProfileResponse, DosenProfileError>(
    ['dosens', 'profile'],
    async () => {
      try {
        return await api<DosenProfileResponse>(`/kmm/dosens/profile`)
      } catch (error: any) {
        if (error instanceof AxiosError) {
          throw error.response?.data
        }
      }
    },
    { ...opts }
  )
}

export const useAPICreateDosenProfile = () => {
  return useMutation<Response<CreateDosenProfileResponse>, AxiosError>((_) =>
    api(`/kmm/dosens/profile`, {
      method: 'POST',
    })
  )
}

export const useAPIUpdateDosenProfile = () => {
  return useMutation<
    UpdateDosenProfileResponse,
    AxiosError,
    UpdateDosenProfileBody
  >((params) => api(`/kmm/dosens/profile`, { method: 'PATCH', data: params }))
}
