import * as React from 'react'

import { Footer } from 'components/Footer'
import { SimpleNavbar } from 'components/SimpleNavbar'

export const Basic = ({
  children,
  mobileTitle,
  hideMobileNavbar,
}: {
  children: React.ReactNode
  mobileTitle?: string
  hideMobileNavbar?: boolean
}) => {
  return (
    <div className="min-h-screen">
      <SimpleNavbar title={mobileTitle} mobileHidden={hideMobileNavbar} />
      {children}
      <Footer />
    </div>
  )
}
