import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { Avatar } from '@wartek-id/avatar'
import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { GATrackEvent } from 'utils/googleAnalytics'

import { useUserStore } from 'stores'

import { useAPIGetDosenProfile } from 'apis/dosens/useAPIDosens'
import { useGetFileDetail } from 'apis/files/useAPIFiles'

export const ProfileDropdown = () => {
  const Router = useRouter()
  const { logged, logout, user } = useUserStore()

  const [isLoggedIn, setIsLoggedIn] = React.useState(false)

  const { data: dosenProfileData } = useAPIGetDosenProfile()
  const { data: profilePic } = useGetFileDetail({
    fileName: dosenProfileData?.data?.profile_photo_file_name,
    opts: { enabled: !!dosenProfileData },
  })
  const onNavigateToLogin = () => {
    GATrackEvent({
      eventName: 'login',
      eventParams: {
        user_id: user?.id ?? '',
      },
    })
    Router.push(process.env.NEXT_PUBLIC_MBKM_URL + '/login')
  }

  const handleRedirect = (redirect: 'profil' | 'bookmark') => () => {
    GATrackEvent({
      eventName: redirect === 'profil' ? 'account' : 'bookmark',
      eventParams: {
        user_id: user?.id ?? '',
      },
    })
  }

  const handleLogout = () => {
    logout()
    setIsLoggedIn(false)

    GATrackEvent({
      eventName: 'logout',
      eventParams: {
        user_id: user?.id ?? '',
      },
    })
  }

  React.useEffect(() => {
    setIsLoggedIn(logged())
  }, [logged])

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        {isLoggedIn ? (
          <Menu.Button className="focus-visible:ring-opacity-7 hover:bg-opacity-90 hidden w-full items-center justify-between gap-2 rounded-2xl bg-white px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white md:flex">
            <Icon size={24}>expand_more</Icon>
            <Text variant="helper-bold">{user?.name.split(' ')[0]}</Text>
            <Avatar
              src={profilePic?.data?.url ?? ''}
              className="!h-9 !w-9"
              alt="profile-picture"
              fallbackColor="blue"
              name={user?.name}
            />
          </Menu.Button>
        ) : (
          <Button
            color="white"
            onClick={onNavigateToLogin}
            className="hidden md:block"
          >
            Masuk ke Akun
          </Button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="shadow-slate-20 absolute right-0 z-10 mt-2 w-64 origin-top-right overflow-hidden rounded-2xl bg-white py-1 shadow-md  focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <div className={clsx(active ? 'bg-gray-10' : '')}>
                <Link href="/account/my-profile">
                  <div
                    className="flex cursor-pointer gap-2 px-4 py-3 align-middle font-bold"
                    onClick={handleRedirect('profil')}
                  >
                    <Icon>person_outlined</Icon>
                    Akun
                  </div>
                </Link>
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div className={clsx(active ? 'bg-gray-10' : '')}>
                <Link href="/bookmarked">
                  <div
                    className="flex cursor-pointer gap-2 px-4 py-3 align-middle font-bold"
                    onClick={handleRedirect('bookmark')}
                  >
                    <Icon>bookmark_outlined</Icon>
                    Daftar Tersimpan
                  </div>
                </Link>
              </div>
            )}
          </Menu.Item>
          <Separator />
          <Menu.Item>
            {({ active }) => (
              <button
                className={clsx(
                  active ? 'bg-gray-10' : '',
                  'block w-full px-4 py-3 text-left'
                )}
                onClick={handleLogout}
              >
                Keluar
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default ProfileDropdown
