import logger from './logger'

export interface GATrackEventArgs {
  eventName: Gtag.EventNames | string
  eventParams: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
  sendDefaultParams?: boolean
}

export const GATrackEvent = ({ eventName, eventParams }: GATrackEventArgs) => {
  if (process.env.GA_ENABLED === 'false') return
  logger({ eventName, eventParams }, 'GAEvent')
  gtag('event', eventName, {
    ...eventParams,
  })
}
